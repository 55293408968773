<template>
  <tangled-lines-svg ref="target" class="sections-tangled-lines" />
</template>

<script lang="ts" setup>
import type { AnimeInstance } from 'animejs'
import TangledLinesSvg from '@/assets/lines/content.svg?component'

const animate = ref(false)
const target = ref()
const targetIsVisible = useElementVisibility(target)
const lines = ref<AnimeInstance>()

const handleMouseScroll = () => {
  if (!lines.value || !targetIsVisible.value) return

  const windowHeight = window.innerHeight
  const elementTop = target.value.$el.getBoundingClientRect().top * -1 + 60
  const elementHeight = target.value.$el.clientHeight
  const scrollPercent = ((elementTop - windowHeight / 2) / elementHeight) * 100

  lines.value.tick(scrollPercent * 7.5)
}

if (animate.value) {
  onMounted(() => {
    lines.value = anime({
      targets: '.sections-tangled-lines path',
      strokeDashoffset: [anime.setDashoffset, 0],
      autoplay: false,
      elasticity: 600,
      easing: 'easeInOutSine',
    })

    window.addEventListener('scroll', handleMouseScroll)
  })

  onBeforeUnmount(() => {
    lines.value?.pause()
    window.removeEventListener('scroll', handleMouseScroll)
  })
}
</script>

<style scoped>
.sections-tangled-lines {
  position: absolute;
  top: 8%;
  right: -60px;
  height: 84%;
}
</style>
