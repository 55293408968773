import RecommendationsJson from '@/recommendations.json'

export type Recommendation = {
  id: number
  author: string
  date: string
  rating: number
  content: string
  type: 'psychiatra' | 'psycholog'
}

export type RecommendationType = Recommendation['type']

export const getRecommendations = (
  type: RecommendationType,
  limit: number = 0,
) => {
  const data = RecommendationsJson.filter(
    (recommendation) => recommendation.type === type,
  ) as Recommendation[]
  return limit ? data.slice(0, limit) : data
}
