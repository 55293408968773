<template>
  <app-section-center full-height>
    <template #background>
      <tangled-lines-svg
        ref="lines"
        class="intro-tangled-lines"
        :style="{ height: '80svh' }"
      />
    </template>

    <v-spacer v-if="$vuetify.display.smAndDown" />
    <div class="text-h1 text-center">
      Jak możemy
      <div class="d-flex flex-nowrap justify-center">
        Tobie
        <app-faces
          class="mx-2 mx-md-3"
          :images="[Face1, Face2, Face3, Face4, Face5, Face6]"
          :max-width="$vuetify.display.mdAndUp ? 75 : 40"
          :min-width="$vuetify.display.mdAndUp ? 75 : 40"
          width="100%"
        />
        pomóc?
      </div>
    </div>
    <v-spacer v-if="$vuetify.display.smAndDown" />

    <div
      class="d-flex ga-2 ga-md-4 flex-wrap justify-center align-center mt-md-6"
    >
      <app-btn
        :block="$vuetify.display.smAndDown"
        color="primary"
        text="Potrzebuję konsultacji"
        :to="{ name: '/umow-wizyte' }"
      />
      <app-btn
        :block="$vuetify.display.smAndDown"
        color="primary"
        text="Szukam informacji"
        :to="{ name: '/jak-pomagamy' }"
      />
    </div>
  </app-section-center>
</template>

<script lang="ts" setup>
import TangledLinesSvg from '@/assets/lines/intro.svg?component'
import Face1 from '@/assets/faces/face-1.png'
import Face2 from '@/assets/faces/face-2.png'
import Face3 from '@/assets/faces/face-3.png'
import Face4 from '@/assets/faces/face-4.png'
import Face5 from '@/assets/faces/face-5.png'
import Face6 from '@/assets/faces/face-6.png'
import type { AnimeInstance } from 'animejs'

const lines = ref(null)
const linesIsVisible = useElementVisibility(lines)
const animation = ref<AnimeInstance>()

onMounted(() => {
  animation.value = anime({
    targets: '.intro-tangled-lines path',
    strokeDashoffset: [anime.setDashoffset, 0],
    easing: 'easeInOutElastic(7, .6)',
    duration: 15000,
    direction: 'alternate',
    loop: true,
  })
})

onBeforeUnmount(() => {
  animation.value?.pause()
})

watch(linesIsVisible, (isVisible) =>
  isVisible ? animation.value?.play() : animation.value?.pause(),
)
</script>
