<template>
  <app-section-showcase
    subtitle="O zdrowiu psychicznym z pasją, wiedzą i doświadczeniem."
    title="Blog"
  >
    <app-carousel class="my-5 my-md-0" :items="posts">
      <template #item="{ item }">
        <app-blog-tile :key="item.url" :post="item" />
      </template>
    </app-carousel>
    <template #button>
      <div class="w-100 text-center text-md-left">
        <app-btn
          append-icon="mdi-arrow-top-right"
          class="text-none"
          color="black"
          :height="38"
          size="small"
          text="Zobacz więcej wpisów"
          :to="{ name: '/blog/' }"
        />
      </div>
    </template>
  </app-section-showcase>
</template>

<script lang="ts" setup>
import { getPosts } from '@/lib/blog'
const posts = getPosts(6)
</script>
