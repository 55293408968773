<template>
  <v-responsive
    :aspect-ratio="
      $vuetify.display.smAndUp ? appDisplay.aspectRatio.value : undefined
    "
    class="position-relative overflow-visible"
  >
    <background-image
      :style="{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 0,
      }"
    />
    <v-container
      class="d-flex justify-center align-center fill-height position-relative select-none"
      fluid
      :style="{ zIndex: 2 }"
    >
      <div class="d-flex align-center text-primary ga-2 ga-md-5">
        <div class="text-meet-us-1" v-text="'Poznaj'" />

        <app-faces
          v-if="$vuetify.display.smAndUp"
          :aspect-ratio="1"
          :images="[KasiaImage]"
          :to="{ name: '/lekarze/katarzyna-kopyto' }"
          :width="75"
        />
        <app-faces
          v-if="$vuetify.display.smAndUp"
          :aspect-ratio="1"
          :images="[PrzemekImage]"
          :to="{ name: '/lekarze/przemyslaw-kopyto' }"
          :width="75"
        />
        <div
          class="text-meet-us-2"
          :style="{
            marginTop: $vuetify.display.smAndUp ? '-15px' : '-5px',
          }"
          v-text="'Nas'"
        />
      </div>
      <template v-if="$vuetify.display.smAndUp">
        <v-img
          :src="ArrowKasia"
          :style="{
            position: 'absolute',
            transform: $vuetify.display.lgAndUp
              ? 'translate(-50px, 100px)'
              : 'translate(-100px, 100px)',
          }"
          :width="170"
        />
        <v-img
          :src="ArrowPrzemek"
          :style="{
            position: 'absolute',
            transform: $vuetify.display.lgAndUp
              ? 'translate(220px, -125px)'
              : 'translate(160px, -130px)',
          }"
          :width="160"
        />
        <template v-if="$vuetify.display.mdAndUp">
          <v-img
            :aspect-ratio="1"
            :src="Face1"
            :style="{
              position: 'absolute',
              transform: 'translate(-540px, 180px)',
            }"
            :width="75"
          />
          <v-img
            :aspect-ratio="1"
            :src="Face2"
            :style="{
              position: 'absolute',
              transform: 'translate(440px, -215px)',
            }"
            :width="75"
          />
          <v-img
            :aspect-ratio="1"
            :src="Face3"
            :style="{
              position: 'absolute',
              transform: 'translate(560px, 110px)',
            }"
            :width="75"
          />
        </template>
      </template>
      <template v-else>
        <div class="d-flex ga-2 justify-center w-100">
          <app-doctors-tile
            v-for="doctor in doctors"
            :key="doctor.name"
            class="w-100"
            :doctor="doctor"
          />
        </div>
      </template>
    </v-container>
  </v-responsive>
</template>

<script lang="ts" setup>
import KasiaImage from '@/assets/team/team-kasia.jpg'
import PrzemekImage from '@/assets/team/team-przemek.jpg'
import ArrowKasia from '@/assets/team/team-arrow-kasia.svg?url'
import ArrowPrzemek from '@/assets/team/team-arrow-przemek.svg?url'
import Face1 from '@/assets/team/team-1.jpg'
import Face2 from '@/assets/team/team-2.jpg'
import Face3 from '@/assets/team/team-3.jpg'
import backgroundImage from '@/assets/team/team-background.svg?component'
import { useAppDisplay } from '@/lib/style'
import { getDoctors } from '@/lib/doctors'

const appDisplay = useAppDisplay()
const doctors = getDoctors()
</script>
