<template>
  <app-section class="text-md-center">
    <h2 class="text-h1-secondary mb-5">Jaka jest <em>Twoja</em> historia?</h2>
    <div
      class="text-body-1 font-weight-light mx-md-auto"
      style="max-width: 680px"
    >
      <app-sierotki v-slot="{ fix }">
        <span
          class="text-purple font-weight-medium"
          v-html="fix('Każdy z nas')"
        />&nbsp;
        <span
          v-html="fix('jest uczestnikiem historii, każdy nosi w sobie swoje')"
        />&nbsp;
        <span
          class="text-purple font-weight-medium"
          v-html="fix('własne opowieści.')"
        />&nbsp;
        <span
          v-html="
            fix(
              'Ja i Ty mamy przed sobą historię do napisania, czasem gubimy się w jej stronach.',
            )
          "
        />&nbsp;
        <span
          v-html="
            fix(
              'Pozwalamy minionym wydarzeniom kształtować to, co przed nami, często nie potrafimy się uporać.',
            )
          "
        />&nbsp;
        <span
          class="text-purple font-weight-medium"
          v-html="
            fix('Trzymamy je w sobie pozwalając zakłócać naszą codzienność.')
          "
        />
      </app-sierotki>
    </div>
  </app-section>
</template>
